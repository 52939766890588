
import React from "react";
import AuthMode from "../Utils/RouteSentry/AuthMode";
import MainLayout from "../components/Layout/MainLayout";
import config from "../config/appConfig";
import ListCourse from "../views/Course/ListCourse";
import SoundAnalysis from "../views/soundAnalyzer";
import UserProfile from "../views/user/userProfile";
import EditProfile from "../views/user/editUserProfile";
import IntroPage from "../views/user/UserIntro/userIntro";
import Home from "../views/LandingPage/Home";
import ListModules from "../views/Modules/ListModules";
import ModuleIntro from "../views/Modules/ModuleIntro";
import Payment from "../views/Payment/Payment";
import Video from "../views/Videos/Video";
import AdminListLesson from "../views/Admin/Lessons/ListLessons";
import AdminLessons from "../views/Admin/Lessons/AddLesson";
import Dashboard from "../views/Admin/dashboard";
import ModuleLists from "../views/Admin/Module/ModuleLists";
import AdminLevels from "../views/Admin/Levels/ListLevels";
import AdminCourses from "../views/Admin/Courses/ListCourses";
import AddModulePage from "../views/Admin/Module/AddModulePage";
import AddLevelPage from "../views/Admin/Levels/AddLevelPage";
import EditCourse from "../views/Admin/Courses/EditCourse";
import Announcement from "../views/Announcements/Announcement";
import AddPost from "../views/Announcements/AddPost";
import PurchaseList from "../views/Admin/Purchase/PurchaseList";
import ProtectedRoutes from "./ProtectedRoutes";

const Routes = config.routes;

const privateRoutes: any = {
	path: "/",
	element:
		<AuthMode>
			<MainLayout />
		</AuthMode>
	,
	children: [
		{
			path   : Routes.landingHome,
			element: <ProtectedRoutes element={<Home />} requiredRole={config.userTypes.user} />
		},
		{
			path   : Routes.course,
			element: <ProtectedRoutes element={<ListCourse />} requiredRole={config.userTypes.user} />
		},
		{
			path   : Routes.soundAnalysis,
			element: <ProtectedRoutes element={<SoundAnalysis />} requiredRole={config.userTypes.user} />
		},
		{
			path   : Routes.userProfile,
			element: <ProtectedRoutes element={<UserProfile />} requiredRole={config.userTypes.user} />
		},
		{
			path   : Routes.editProfile,
			element: <ProtectedRoutes element={<EditProfile />} requiredRole={config.userTypes.user} />
		},
		{
			path   : Routes.userIntro,
			element: <ProtectedRoutes element={<IntroPage />} requiredRole={config.userTypes.user} />
		},
		{
			path   : Routes.modules,
			element: <ProtectedRoutes element={<ListModules />} requiredRole={config.userTypes.user} />
		},
		{
			path   : Routes.moduleIntro,
			element: <ProtectedRoutes element={<ModuleIntro />} requiredRole={config.userTypes.user} />
		},
		{
			path   : Routes.payment,
			element: <ProtectedRoutes element={<Payment />} requiredRole={config.userTypes.user} />
		},
		{
			path   : Routes.video,
			element: <ProtectedRoutes element={<Video />} requiredRole={config.userTypes.user} />
		},
		{
			path   : Routes?.listLessons,
			element: <ProtectedRoutes element={<AdminListLesson />} requiredRole={config.userTypes.systemAdmin} />
		},
		{
			path   : Routes?.lessons,
			element: <ProtectedRoutes element={<AdminLessons />} requiredRole={config.userTypes.systemAdmin} />
		},
		{
			path   : Routes.dashboard,
			element: <ProtectedRoutes element={<Dashboard />} requiredRole={config.userTypes.systemAdmin} />
		},
		{
			path   : Routes.listModules,
			element: <ProtectedRoutes element={<ModuleLists />} requiredRole={config.userTypes.systemAdmin} />
		},
		{
			path   : Routes.listLevels,
			element: <ProtectedRoutes element={<AdminLevels />} requiredRole={config.userTypes.systemAdmin} />
		},
		{
			path   : Routes.listCourse,
			element: <ProtectedRoutes element={<AdminCourses />} requiredRole={config.userTypes.systemAdmin} />
		},
		{
			path   : Routes.addModulePage,
			element: <ProtectedRoutes element={<AddModulePage />} requiredRole={config.userTypes.systemAdmin} />
		},
		{
			path   : Routes.addLevelPage,
			element: <ProtectedRoutes element={<AddLevelPage />} requiredRole={config.userTypes.systemAdmin} />
		},
		{
			path   : Routes.editCourse,
			element: <ProtectedRoutes element={<EditCourse/>} requiredRole={config.userTypes.systemAdmin} />
		},
		{
			path   : Routes.announcement,
			element: <ProtectedRoutes element={<Announcement />} requiredRole={config.userTypes.systemAdmin} />
		},
		{
			path   : Routes.addPost,
			element: <ProtectedRoutes element={<AddPost />} requiredRole={config.userTypes.systemAdmin} />
		},
		{
			path   : Routes.purchaseHistory,
			element: <ProtectedRoutes element={<PurchaseList />} requiredRole={config.userTypes.systemAdmin} />
		}
	]
};
export default privateRoutes;
