
import "firebase/compat/auth";
import React, { createContext, useEffect, useReducer, useState, useContext } from "react";
import { auth } from "../firebase";
import accountReducer from "../store/accountReducer";
import { LOGIN, LOGOUT } from "../store/actions";

import {
	GoogleAuthProvider,
	createUserWithEmailAndPassword,
	onAuthStateChanged,
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
	signInWithPopup,
	signOut,
	sendEmailVerification
} from "firebase/auth";


type Context = {
  createUser: any;
  users: any;
  logout: any;
  signIn: any;
  token: any;
  googleSignIn: any;
  forgotPassword: any;
  sendEmailVerificationToFirebase: any;
  setUserDetails: any;
  userDetails: any;
  setIsAnnouncementFetch: any;
  isAnnouncementFetch: any;
};

const initialState = {
	isLoggedIn   : false,
	isInitialized: false,
	user         : null
};


const UserContext = createContext({} as Context);

export const AuthContextProvider = ({ children }: any) => {
  
	const [ users, setUsers ] = useState<any>({});
	const [ state, dispatch ] = useReducer(accountReducer, initialState);
	const [ token, setToken ] = useState<any>({});
	const [ userDetails, setUserDetails ] = useState<any>({});
	const [ isAnnouncementFetch, setIsAnnouncementFetch ] = useState(false);
  
	let authTokenRefreshDemon = undefined;
  
	if (authTokenRefreshDemon == undefined) {
  
		authTokenRefreshDemon = setInterval(() => {
  
			if (auth && auth.currentUser) {
  
				if (Date.now() > JSON.parse(JSON.stringify(auth.currentUser))?.stsTokenManager.expirationTime - 5 * 60 * 1000) {
  
					auth.currentUser?.getIdToken(true);
  
				}
  
			}
  
		}, 1 * 60 * 1000);
  
	}
	const createUser = (email: any, password: any) => {

		return createUserWithEmailAndPassword(auth, email, password);

	};

	const signIn = async (email: any, password: any) => {

		const res = await signInWithEmailAndPassword(auth, email, password);

		if (auth && auth.currentUser) {

			if (auth.currentUser.emailVerified === false) {

				return;

			} else {

				dispatch({
					type   : LOGIN,
					payload: {
						isLoggedIn: true,
						user      : auth.currentUser
					}

				});

				return res;

			}

		}

	};

	const googleSignIn = async () => {

		const provider = new GoogleAuthProvider();

		const res = await signInWithPopup(auth, provider);

		if (auth && auth.currentUser) {

			if (auth.currentUser.emailVerified === false) {

				return res;

			} else {

				dispatch({
					type   : LOGIN,
					payload: {
						isLoggedIn: true,
						user      : auth.currentUser
					}

				});

				return res;

			}

		}

		return res;

	};

	const logout = async () => {

		setUsers({});
		dispatch({
			type: LOGOUT
		});

		setToken({});
    
		await signOut(auth);

	};

	const forgotPassword = (email: any) => {

		return sendPasswordResetEmail(auth, email);

	};

	const sendEmailVerificationToFirebase = () => {

		if (auth && auth.currentUser) {

			return sendEmailVerification(auth?.currentUser);

		}

	};

	useEffect(() => {

		const unsubscribe = onAuthStateChanged(auth, async (currentUser: any) => {

			if (currentUser) {

				setUsers(currentUser);

				if (currentUser.emailVerified === false) {

					return;

				}

			}


		});


		return () => {

			unsubscribe();

			dispatch({
				type: LOGOUT
			});

		};

	}, []);


	useEffect(() => {

		if (users && users.accessToken) {

			users.getIdTokenResult().then((tokenDetails: any) => {

				setToken(tokenDetails.token);

				localStorage.setItem("fbToken", tokenDetails.token);

			});

		}


	}, [ users, state.isLoggedIn ]);

	return (
		<UserContext.Provider value={{ ...state, createUser, token, users, logout, signIn, googleSignIn, forgotPassword, sendEmailVerificationToFirebase, setUserDetails, userDetails, setIsAnnouncementFetch, isAnnouncementFetch }}>
			{children}
		</UserContext.Provider>
	);

};

export const UserAuth = () => {

	return useContext(UserContext);

};

export default UserContext;
