// React import
import React, { useState } from "react";

// MUI, RSUITE, SLIDEr imports
import Container from "@mui/material/Container";
import { Row } from "rsuite";
import Slider from "react-slick";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Panel, PanelGroup } from "rsuite";

// style page import
import "./landingPage.css";

// image imports from local
import Learn from "../../assets/images/classical-music-made-simple.svg";
import Exclusive from "../../assets/images/module-based-step-by-step-learning.svg";
import Song from "../../assets/images/track-your-progress.svg";
import Customized from "../../assets/images/professional-play-alongs.svg";
import Personalized from "../../assets/images/periodic-online-meets-with-teachers.svg";
import CollageImage from "../../assets/images/collaged-image.svg";
import Module1 from "../../assets/images/Modules1.svg";
import Module2 from "../../assets/images/Modules2.svg";
import Module3 from "../../assets/images/Modules3.svg";
import Module4 from "../../assets/images/Modules4.svg";
import Module5 from "../../assets/images/Modules5.svg";
import icon from "../../assets/logo/soulswara-primary-A.png";
import { SlArrowRight, SlArrowLeft } from "react-icons/sl";

// image imports from react-icons
import { IoLocationOutline } from "react-icons/io5";
import { IoMdPhonePortrait } from "react-icons/io";
import { IoMailOutline } from "react-icons/io5";
import { FaInstagram } from "react-icons/fa";
import { AiOutlineYoutube } from "react-icons/ai";
import { SlSocialFacebook } from "react-icons/sl";
import { useNavigate } from "react-router-dom";
import config from "../../config/appConfig";

const LandingPage = () => {

	const theme: any = useTheme();
	const [ activeIndex, setActiveIndex ] = useState(1);
	const [ activeSlide, setActiveSlide ] = useState<any>(0);

	const toggleAccordion = (index: any) => {

		setActiveIndex(activeIndex === index ? null : index);

	};

	// console.info(theme);

	const navigate = useNavigate();

	const modulesData = [
		{
			"moduleName": "Orientation – Your Violin Journey Starts Here",
			"moduleIcon": "./frontend/src/assets/images/exe-cource-tumb.jpg",
			"image"     : Module1,
			"author"    : "Karthik"
		},
		{
			"moduleName": "Bowing and The First String",
			"moduleIcon": "/frontend/src/assets/images/exe-cource-tumb.jpg",
			"image"     : Module2,
			"author"    : "Karthik"
		},
		{
			"moduleName": "The Second-String Joins In",
			"moduleIcon": "/frontend/src/assets/images/exe-cource-tumb.jpg",
			"image"     : Module3,
			"author"    : "Karthik"
		},
		{
			"moduleName": "Kangal Irandal",
			"moduleIcon": "/frontend/src/assets/images/exe-cource-tumb.jpg",
			"image"     : Module4,
			"author"    : "Karthik"
		},
		{
			"moduleName": "Rama Janardhana",
			"moduleIcon": "/frontend/src/assets/images/exe-cource-tumb.jpg",
			"image"     : Module5,
			"author"    : "Karthik"
		}
	];

	const [ activeTab, setActiveTab ] = useState(0);

	const handleTabClick = (index: any) => {

		setActiveTab(index);

	};

	const PrevArrow = (props: any) => {

		const { className, style, onClick } = props;

		return (
			<div className={className} style={{ ...style, display: "block", color: parseFloat(activeSlide) == 0 ? theme.palette?.grey[800] : theme.palette.text.primary }} onClick={onClick}>
				{/* &lt; */}
				<SlArrowLeft className="sh-landing-page-left-arrow" />
			</div>
		);

	};

	const NextArrow = (props: any) => {

		const { className, style, onClick } = props;

		return (
			<div className={className} style={{ ...style, display: "block", color: window?.innerWidth > 400 ? parseFloat(activeSlide) + 3 == modulesData?.length ? theme.palette?.grey[800] : theme.palette.text.primary : parseFloat(activeSlide) == modulesData?.length - 1 ? theme.palette?.grey[800] : theme.palette.text.primary }} onClick={onClick}>
				{/* &gt; */}
				<SlArrowRight className="sh-landing-page-right-arrow" />
			</div>
		);

	};

	// const scrollIntoView = () => {

	// 	const elmntToView: any = document.getElementById("shLandingPageVideoSection");

	// 	elmntToView?.scrollIntoView();

	// };

	const settings = {
		dots          : false,
		infinite      : false,
		speed         : 500,
		slidesToShow  : 3,
		slidesToScroll: 1,
		className     : "center",
		centerMode    : false,
		nextArrow     : <NextArrow
			className={"sh-landing-page-next-arrow"}
			style={{ color: theme.palette.text.primary }}
		/>
		,
		prevArrow: <PrevArrow
			className={"sh-landing-page-prev-arrow"}
			style={{ color: theme.palette.text.primary }}
		/>,
		afterChange: (current: any) => setActiveSlide(current),
		responsive : [
			{
				breakpoint: 1200,
				settings  : {
					slidesToShow  : 2,
					slidesToScroll: 1,
					infinite      : false
				}
			},
			{
				breakpoint: 600,
				settings  : {
					slidesToShow  : 1,
					slidesToScroll: 1,
					infinite      : false
				}
			}

		]

	};

	return (
		<div className="landing-page">
			<div className="landing-page-full-height-con mobile-view-landing-page-full-height-con landing-page-section-1-con-home sh-landing-page-home-route-banner-section">
				<Container>
					<Row gutter={0} className="justify-content-md-center sh-landing-page-section-1-row-con">
						<div className="sh-landing-page-level-up-line" style={{ color: theme.palette.text.primary }}>Start your Violin Journey <br className="sh-line-breaker-to-hide-mobile" />  With Soul Swara!</div>
						<div className="sh-landing-page-your-boss-line" style={{ color: theme.palette.text.primary }}>Learn what you want at you own pace. <br /> <span style={{ color: theme?.palette?.primary?.main }}>You are just one click away!</span></div>
						{/* <div className="sh-landing-page-tagline-title" style={{
							color: theme.palette.text.primary
						}}>Get <strong>everything</strong> you need to Soul Swara, all in one place</div> */}
						<button className="sh-landing-page-join-us-button" onClick={() => navigate(config?.routes?.userIntro)} style={{ color: theme?.palette?.common?.white, backgroundColor: theme.palette.secondary.main }}>
							<a onClick={() => navigate(config?.routes?.userIntro)} style={{ color: theme?.palette?.common?.white }}>
								Continue <span></span>
							</a>
						</button>
						{/* <Lottie onClick={scrollIntoView} animationData={arrowDown} className="sh-landing-page-lottie-down-arrow" /> */}
					</Row>
				</Container>
			</div>
			<div className="landing-page-combined-sec-2-3-background-image">
				<div id="shLandingPageVideoSection" className="landing-page-full-height-con landing-page-section-2-con">
					<Row className="justify-content-md-center sh-landing-page-section-2-row-con">
						<div className="sh-landing-page-section-2-black-background">
							{/* <div className="sh-landing-page-section-2-top-white-background"></div> */}
							<Container style={{ padding: "0px" }}>
								<video className="sh-landing-page-video-con" controls controlsList="nodownload noremoteplayback noplaybackrate" autoPlay muted loop>
									<source src={`${config.appDomain}/${config?.teaserVideo}`} type="video/mp4" />
									Your browser does not support the video tag.
								</video>
							</Container>
						</div>
					</Row>
				</div>
				<div className="landing-page-full-height-con landing-page-section-3-con">
					<Container style={{ padding: "0px" }}>
						<Row className="justify-content-md-center sh-landing-page-section-3-row-con">
							<div className="sh-landing-page-section-2-text-con" style={{ color: theme.palette.secondary.main }}>Start your Violin Journey with a FREE TRIAL!</div>
							<button className="sh-landing-page-join-us-free-trial-button" onClick={() => navigate(config?.routes?.login)} style={{ color: theme.palette.text.primary, backgroundColor: theme?.palette?.primary?.main }}>
								<a className="sh-landing-page-lets-gets-btn-link-tag" onClick={() => navigate(config?.routes?.login)} href="">
									Join Us! FREE TRIAL <span></span>
								</a>
							</button>
						</Row>
					</Container>
				</div>
			</div>
			<div className="landing-page-full-height-con landing-page-section-4-con" style={{ backgroundColor: theme.palette.background.default }}>
				<div style={{ padding: "0px" }}>
					<Container>
						<Row className="justify-content-md-center sh-landing-page-section-4-row-con">
							<Typography variant="h1" style={{ color: theme.palette.secondary.main }} className="sh-landing-page-section-4-title">Featured Courses</Typography>
							<div className="slider-container" style={{ paddingRight: "28px" }}>
								<Slider {...settings}>
									{
										modulesData.map((module, index) => {

											return (
												<div key={index} className="module-card">
													<div className="module-card-image-con">
														<img src={module.image} alt={module.moduleName} className="module-card-image" />
													</div>
													<div className="module-card-title">
														<div>
															<div style={{ color: theme.palette.text.primary }} className="module-card-title-content">{module.moduleName}</div>
															<div style={{ color: theme.palette.secondary.main }}>{module?.author}</div>
														</div>
													</div>
												</div>
											);

										})
									}
								</Slider>
							</div>
						</Row>
					</Container>
				</div>
			</div>
			<div className="landing-page-full-height-con landing-page-section-5-con landing-page-section-4-con-desktop-view" style={{ backgroundColor: theme.palette.background.default }}>
				<Container>
					<Row className="justify-content-md-center sh-landing-page-section-5-row-con">
						<Typography variant="h1" style={{ color: theme.palette.secondary.main }} className="sh-landing-page-section-4-title">What do we offer?</Typography>
						<div className="vertical-tabs">
							<div className="tab-list">
								{/* {tabs.map((tab, index) =>
									<div
										key={index}
										className={`tab-item ${index === activeTab ? "active" : ""}`}
										onClick={() => handleTabClick(index)}
									>
										{tab.label}
									</div>
								)} */}
								<div className={"tab-item"} onClick={() => handleTabClick(0)}>
									<div className="tab-item-inner-con">
										{/* <FontAwesomeIcon style={{ color: theme.palette.secondary.main }} icon={faMusic} /> */}
										<span style={{ color: activeTab === 0 ? theme?.palette?.primary?.main : theme.palette.text.primary }}> Learn What you want to learn</span>
									</div>
									{/* {activeTab === 0 && <FaArrowRightLong color={theme.palette.secondary.main} />} */}
								</div>
								<div className={"tab-item"} onClick={() => handleTabClick(1)}>
									<div className="tab-item-inner-con">
										{/* <BsBarChartSteps color={theme.palette.secondary.main} /> */}
										<span style={{ color: activeTab === 1 ? theme?.palette?.primary?.main : theme.palette.text.primary }}>Exclusive Learning Modules</span>
									</div>
									{/* {activeTab === 1 && <FaArrowRightLong color={theme.palette.secondary.main} />} */}
								</div>
								<div className={"tab-item"} onClick={() => handleTabClick(2)}>
									<div className="tab-item-inner-con">
										{/* <GiProgression color={theme.palette.secondary.main} /> */}
										<span style={{ color: activeTab === 2 ? theme?.palette?.primary?.main : theme.palette.text.primary }}>Song Oriented Learning</span>
									</div>
									{/* {activeTab === 2 && <FaArrowRightLong color={theme.palette.secondary.main} />} */}
								</div>
								<div className={"tab-item"} onClick={() => handleTabClick(3)}>
									<div className="tab-item-inner-con">
										{/* <FiPlay color={theme.palette.secondary.main} /> */}
										<span style={{ color: activeTab === 3 ? theme?.palette?.primary?.main : theme.palette.text.primary }}>Customized QnA Session with Violin Experts</span>
									</div>
									{/* {activeTab === 3 && <FaArrowRightLong color={theme.palette.secondary.main} />} */}
								</div>
								<div className={"tab-item"} onClick={() => handleTabClick(4)}>
									<div className="tab-item-inner-con">
										{/* <GiTeacher color={theme.palette.secondary.main} /> */}
										<span style={{ color: activeTab === 4 ? theme?.palette?.primary?.main : theme.palette.text.primary }}>Personalized Feedback and Practice Forums</span>
									</div>
									{/* {activeTab === 4 && <FaArrowRightLong color={theme.palette.secondary.main} />} */}
								</div>
							</div>
							<div className="tab-content">
								{
									activeTab === 0 &&
									<div>
										<div className="sh-landing-page-vertical-tab-con">
											<div className="landing-page-section-5-content-side-heading" style={{ color: theme?.palette?.primary?.main }}>Learn What you want to learn</div>
											<div style={{ color: theme.palette.text.primary }} className="landing-page-section-5-content-side-content-para">Our learning pathways and players path platforms remove the guesswork from learning the violin and guide you through the technique and skills needed to reach your goals</div>
											<div className="sh-landing-page-offer-section-image">
												<img src={Learn} alt="" className="sh-landing-page-vertical-tab-dummy-image" />
											</div>
										</div>
									</div>
								}
								{
									activeTab === 1 &&
									<div>
										<div className="sh-landing-page-vertical-tab-con">
											<div className="landing-page-section-5-content-side-heading" style={{ color: theme?.palette?.primary?.main }}>Exclusive Learning Modules</div>
											<div style={{ color: theme.palette.text.primary }} className="landing-page-section-5-content-side-content-para">Our learning pathways and players path platforms remove the guesswork from learning bass, and guide you through the technique and skills needed to reach your goals</div>
											<div className="sh-landing-page-offer-section-image">
												<img src={Exclusive} alt="" className="sh-landing-page-vertical-tab-dummy-image" />
											</div>
										</div>
									</div>
								}
								{
									activeTab === 2 &&
									<div>
										<div className="sh-landing-page-vertical-tab-con">
											<div className="landing-page-section-5-content-side-heading" style={{ color: theme?.palette?.primary?.main }}>Song Oriented Learning</div>
											<div style={{ color: theme.palette.text.primary }} className="landing-page-section-5-content-side-content-para">Our learning pathways and players path platforms remove the guesswork from learning bass, and guide you through the technique and skills needed to reach your goals</div>
											<div className="sh-landing-page-offer-section-image">
												<img src={Song} alt="" className="sh-landing-page-vertical-tab-dummy-image" />
											</div>
										</div>
									</div>
								}
								{
									activeTab === 3 &&
									<div>
										<div className="sh-landing-page-vertical-tab-con">
											<div className="landing-page-section-5-content-side-heading" style={{ color: theme?.palette?.primary?.main }}>Customized QnA Session with Violin Experts</div>
											<div style={{ color: theme.palette.text.primary }} className="landing-page-section-5-content-side-content-para">Our learning pathways and players path platforms remove the guesswork from learning bass, and guide you through the technique and skills needed to reach your goals</div>
											<div className="sh-landing-page-offer-section-image">
												<img src={Customized} alt="" className="sh-landing-page-vertical-tab-dummy-image" />
											</div>
										</div>
									</div>
								}
								{
									activeTab === 4 &&
									<div>
										<div className="sh-landing-page-vertical-tab-con">
											<div className="landing-page-section-5-content-side-heading" style={{ color: theme?.palette?.primary?.main }}>Personalized Feedback and Practice Forums</div>
											<div style={{ color: theme.palette.text.primary }} className="landing-page-section-5-content-side-content-para">Our learning pathways and players path platforms remove the guesswork from learning bass, and guide you through the technique and skills needed to reach your goals</div>
											<div className="sh-landing-page-offer-section-image">
												<img src={Personalized} alt="" className="sh-landing-page-vertical-tab-dummy-image" />
											</div>
										</div>
									</div>
								}
							</div>
						</div>
					</Row>
				</Container>
			</div>
			<div className="landing-page-full-height-con landing-page-section-5-con landing-page-section-4-con-mobile-view" style={{ backgroundColor: theme.palette.background.default }}>
				<Container>
					<Row className="justify-content-md-center sh-landing-page-section-5-row-con">
						<Typography variant="h1" style={{ color: theme.palette.secondary.main }} className="sh-landing-page-section-4-title sh-landing-page-section-4-title-mobile sh-landing-page-title-above-space">What do we offer?</Typography>
						<PanelGroup accordion defaultActiveKey={0} bordered={false}>
							<Panel className="sh-accordian-indivial-panel-con" header="Learn What you want to learn" eventKey={0}>
								<div>
									<div className="sh-landing-page-vertical-tab-con">
										<div className="landing-page-section-5-content-side-heading" style={{ color: theme?.palette?.primary?.main }}>Learn What you want to learn</div>
										<div className="landing-page-section-5-content-side-content-para">Our learning pathways and players path platforms remove the guesswork from learning bass, and guide you through the technique and skills needed to reach your goals</div>
										<div className="sh-landing-page-offer-section-image-mobile">
											<img src={Learn} alt="" className="sh-landing-page-vertical-tab-dummy-image" />
										</div>
									</div>
								</div>
							</Panel>
							<Panel className="sh-accordian-indivial-panel-con" header="Exclusive Learning Modules" eventKey={1}>
								<div>
									<div className="sh-landing-page-vertical-tab-con">
										<div className="landing-page-section-5-content-side-heading" style={{ color: theme?.palette?.primary?.main }}>Exclusive Learning Modules</div>
										<div className="landing-page-section-5-content-side-content-para">Our learning pathways and players path platforms remove the guesswork from learning bass, and guide you through the technique and skills needed to reach your goals</div>
										<div className="sh-landing-page-offer-section-image-mobile">
											<img src={Exclusive} alt="" className="sh-landing-page-vertical-tab-dummy-image" />
										</div>
									</div>
								</div>
							</Panel>
							<Panel className="sh-accordian-indivial-panel-con" header="Song Oriented Learning" eventKey={2}>
								<div>
									<div className="sh-landing-page-vertical-tab-con">
										<div className="landing-page-section-5-content-side-heading" style={{ color: theme?.palette?.primary?.main }}>Song Oriented Learning</div>
										<div className="landing-page-section-5-content-side-content-para">Our learning pathways and players path platforms remove the guesswork from learning bass, and guide you through the technique and skills needed to reach your goals</div>
										<div className="sh-landing-page-offer-section-image-mobile">
											<img src={Song} alt="" className="sh-landing-page-vertical-tab-dummy-image" />
										</div>
									</div>
								</div>
							</Panel>
							<Panel className="sh-accordian-indivial-panel-con" header="Customized QnA Session with Violin Experts" eventKey={3}>
								<div>
									<div className="sh-landing-page-vertical-tab-con">
										<div className="landing-page-section-5-content-side-heading" style={{ color: theme?.palette?.primary?.main }}>Customized QnA Session with Violin Experts</div>
										<div className="landing-page-section-5-content-side-content-para">Our learning pathways and players path platforms remove the guesswork from learning bass, and guide you through the technique and skills needed to reach your goals</div>
										<div className="sh-landing-page-offer-section-image-mobile">
											<img src={Customized} alt="" className="sh-landing-page-vertical-tab-dummy-image" />
										</div>
									</div>
								</div>
							</Panel>
							<Panel className="sh-accordian-indivial-panel-con" header="Personalized Feedback and Practice Forums" eventKey={4}>
								<div>
									<div className="sh-landing-page-vertical-tab-con">
										<div className="landing-page-section-5-content-side-heading" style={{ color: theme?.palette?.primary?.main }}>Personalized Feedback and Practice Forums</div>
										<div className="landing-page-section-5-content-side-content-para">Our learning pathways and players path platforms remove the guesswork from learning bass, and guide you through the technique and skills needed to reach your goals</div>
										<div className="sh-landing-page-offer-section-image-mobile">
											<img src={Personalized} alt="" className="sh-landing-page-vertical-tab-dummy-image" />
										</div>
									</div>
								</div>
							</Panel>
						</PanelGroup>
					</Row>
				</Container>
			</div>
			<div className="landing-page-full-height-con landing-page-section-7-con" style={{ backgroundColor: theme.palette.background.default }}>
				<Container>
					<Row className="justify-content-md-center sh-landing-page-section-7-row-con">
						<Typography variant="h1" style={{ color: theme.palette.text.primary, textAlign: "center" }} className="sh-landing-page-section-4-title">Online Indian Classical Music Education Like Never Before</Typography>
						<div className="sh-landing-page-online-indian-section-image">
							<img className="sh-landing-page-collage-image" src={CollageImage} alt="" />
						</div>
					</Row>
				</Container>
			</div>
			<div className="landing-page-section-6-con" style={{ backgroundColor: theme.palette.background.default }}>
				<Container>
					<Row className="justify-content-md-center sh-landing-page-section-6-row-con">
						<Typography variant="h1" style={{ color: theme.palette.secondary.main, textAlign: "center" }} className="sh-landing-page-section-4-title">FAQ</Typography>
						<div className="landing-page-section-6-accordion">
							<div className="landing-page-section-6-accordion-item" key={1}>
								<div
									className="landing-page-section-6-accordion-header" style={{ color: theme.palette.text.primary }}
									onClick={() => toggleAccordion(1)}
								>
									<span>I am already learning from a Guru. How would the courses help me?</span>
									{/* <span className="landing-page-section-6-symbol">
										{activeIndex === 1 ? "-" : "+"}
									</span> */}
								</div>
								<div
									className="landing-page-section-6-accordion-content"
									style={{
										maxHeight: activeIndex === 1 ? "fit-content" : "0",
										padding  : activeIndex === 1 ? "15px 15px 15px 0px" : "0 15px",
										color    : theme.palette.text.primary
									}}
								>
									<div>
										<p>Everyone aspires to play their favourite music on the Violin.
											You get to pick and choose what you want to learn at your own pace.
											All courses are designed such that they will add value to your existing playing, be it Carnatic or Western.</p>
										{/* <p>Exclusive deals with dozens of bass gear and accessory manufacturers. If you spend more than a few hundred dollars a year on strings, upkeep, and other bass gear, these discounts alone will pay for your membership.</p> */}
									</div>
								</div>
							</div>
							<div className="landing-page-section-6-accordion-item" key={2}>
								<div
									className="landing-page-section-6-accordion-header" style={{ color: theme.palette.text.primary }}
									onClick={() => toggleAccordion(2)}
								>
									<span>How is it different from online classes?</span>
									{/* <span className="landing-page-section-6-symbol">
										{activeIndex === 2 ? "-" : "+"}
									</span> */}
								</div>
								<div
									className="landing-page-section-6-accordion-content"
									style={{
										maxHeight: activeIndex === 2 ? "fit-content" : "0",
										padding  : activeIndex === 2 ? "15px 15px 15px 0px" : "0 15px",
										color    : theme.palette.text.primary
									}}
								>
									<div>
										<p>You gain a new skill every 5 minutes you spend on the Course.
											It is extremely simplified to the extent that you can learn at your own pace.
											Unlike usual self paced courses, you get to attend doubt clearing sessions with our skilled teachers.</p>
									</div>
								</div>
							</div>
							<div className="landing-page-section-6-accordion-item" key={3}>
								<div
									className="landing-page-section-6-accordion-header" style={{ color: theme.palette.text.primary }}
									onClick={() => toggleAccordion(3)}
								>
									<span>Who can enroll for these courses?</span>
									{/* <span className="landing-page-section-6-symbol">
										{activeIndex === 3 ? "-" : "+"}
									</span> */}
								</div>
								<div
									className="landing-page-section-6-accordion-content"
									style={{
										maxHeight: activeIndex === 3 ? "fit-content" : "0",
										padding  : activeIndex === 3 ? "15px 15px 15px 0px" : "0 15px",
										color    : theme.palette.text.primary
									}}
								>
									<div>
										<p>Violinists, both Carnatic and Western, of all levels can gain value from the courses available on the app.</p>
									</div>
								</div>
							</div>
							<div className="landing-page-section-6-accordion-item" key={4}>
								<div
									className="landing-page-section-6-accordion-header" style={{ color: theme.palette.text.primary }}
									onClick={() => toggleAccordion(4)}
								>
									<span>What Shruti are the exercises in?</span>
									{/* <span className="landing-page-section-6-symbol">
										{activeIndex === 4 ? "-" : "+"}
									</span> */}
								</div>
								<div
									className="landing-page-section-6-accordion-content"
									style={{
										maxHeight: activeIndex === 4 ? "fit-content" : "0",
										padding  : activeIndex === 4 ? "15px 15px 15px 0px" : "0 15px",
										color    : theme.palette.text.primary
									}}
								>
									<div>
										<p>All videos under the Self Paced Module are in the Carnatic Shruti G (5 kattai).</p>
										<p>All videos under the Songs and Skills Module in the Carnatic Shruti D (2 kattai).</p>
									</div>
								</div>
							</div>
							<div className="landing-page-section-6-accordion-item" key={5}>
								<div
									className="landing-page-section-6-accordion-header" style={{ color: theme.palette.text.primary }}
									onClick={() => toggleAccordion(5)}
								>
									<span>In case I face issues, whom can I contact?</span>
									{/* <span className="landing-page-section-6-symbol">
										{activeIndex === 4 ? "-" : "+"}
									</span> */}
								</div>
								<div
									className="landing-page-section-6-accordion-content"
									style={{
										maxHeight: activeIndex === 5 ? "fit-content" : "0",
										padding  : activeIndex === 5 ? "15px 15px 15px 0px" : "0 15px",
										color    : theme.palette.text.primary
									}}
								>
									<div>
										<p>You can write to <a href="contact@indosoulacademy.com">contact@indosoulacademy.com</a> in case of any queries.</p>
									</div>
								</div>
							</div>
						</div>
					</Row>
				</Container>
			</div>
			<footer className="sh-landing-page-footer-container" style={{ backgroundColor: theme.palette.background.default }}>
				<Container>
					<div>
						<div>
							<div>
								<div className="sh-landing-page-footer-section-1-con">
									<div className="sh-landing-page-footer-section-1-column-1">
										<div>
											<div>
												<img className="sh-landing-page-logo-icon" src={icon} alt="" />
											</div>
											<Typography variant="h5" className="sh-landing-page-text-content sh-landing-page-about-text" style={{ color: theme.palette.text.primary }}>
												VIOLIN TECHNIQUE WITH KARTHICK IYER - ACADEMY OF MUSIC - INDOSOUL
											</Typography>
										</div>
									</div>
									<div className="sh-landing-page-footer-section-1-column-2">
										<div>
											<Typography variant="h5" style={{ color: theme.palette.secondary.main }} className="sh-landing-page-head-content sh-landing-page-get-in-touch-text">
												Get in touch
											</Typography>
											<div>
												<div className="sh-landing-page-footer-display-flex">
													<div>
														{/* <img className="sh-landing-page-footer-icon sh-landing-page-location-icon" src={location} alt="" /> */}
														<IoLocationOutline style={{ color: theme?.palette?.primary?.main }} className="sh-landing-page-footer-icon sh-landing-page-location-icon" />
													</div>
													<Typography variant="h5" className="sh-landing-page-text-content sh-landing-page-loation-text" style={{ color: theme.palette.text.primary }}>
														3B, Nataraja Nilayam, Kalakshetra Avenue, 2nd Street, Chennai, Tamil Nadu, 600041
													</Typography>
												</div>
												<div className="sh-landing-page-footer-display-flex">
													<div>
														{/* <img className="sh-landing-page-footer-icon sh-landing-page-phone-icon" src={smartphone} alt="" /> */}
														<IoMdPhonePortrait style={{ color: theme?.palette?.primary?.main }} className="sh-landing-page-footer-icon sh-landing-page-phone-icon" />
													</div>
													<Typography variant="h5" className="sh-landing-page-text-content sh-landing-page-phone-text" style={{ color: theme.palette.text.primary }}>
														<a className="sh-landing-page-footer-a-tag-styles sh-landing-page-footer-phone-cta" href="tel:+91 8850411961">+91 8850411961</a>
													</Typography>
												</div>
												<div className="sh-landing-page-footer-display-flex">
													<div>
														{/* <img className="sh-landing-page-footer-icon sh-landing-page-email-icon" src={mail} alt="" /> */}
														<IoMailOutline style={{ color: theme?.palette?.primary?.main }} className="sh-landing-page-footer-icon sh-landing-page-email-icon" />
													</div>
													<Typography variant="h5" className="sh-landing-page-text-content sh-landing-page-email-text" style={{ color: theme.palette.text.primary }}>
														<a className="sh-landing-page-footer-a-tag-styles sh-landing-page-footer-mail-to-cta" href="mailto:violintechnique.ki@gmail.com">violintechnique.ki@gmail.com</a>
													</Typography>
												</div>
											</div>
										</div>
									</div>
									<div className="sh-landing-page-footer-section-1-column-3">
										<div>
											<div>
												<Typography variant="h5" style={{ color: theme.palette.secondary.main }} className="sh-landing-page-head-content sh-landing-page-follow-us-text">
													Social Media
												</Typography>
											</div>
											<div>
												<div className="sh-landing-page-footer-display-flex">
													<div>
														{/* <img className="sh-landing-page-footer-icon sh-landing-page-facebook-icon" src={facebook} alt="" /> */}
														<SlSocialFacebook
															onClick={() => window.open("https://www.facebook.com/profile.php?id=100095242332182", "_blank")}
															className="sh-landing-page-footer-icon sh-landing-page-facebook-icon" />
													</div>
													<Typography variant="h5"
														onClick={() => window.open("https://www.facebook.com/profile.php?id=100095242332182", "_blank")}
														className="sh-landing-page-text-content sh-landing-page-facebook-text" style={{ color: theme.palette.text.primary }}>
														<a className="sh-landing-page-footer-a-tag-styles sh-landing-page-footer-facebook">Facebook</a>
													</Typography>
												</div>
												<div className="sh-landing-page-footer-display-flex">
													<div>
														{/* <img className="sh-landing-page-footer-icon sh-landing-page-youtube-icon" src={youtube} alt="" /> */}
														<AiOutlineYoutube
															onClick={() => window.open("https://www.youtube.com/@indosoul_academy", "_blank")}
															className="sh-landing-page-footer-icon sh-landing-page-youtube-icon" />
													</div>
													<Typography variant="h5"
														onClick={() => window.open("https://www.youtube.com/@indosoul_academy", "_blank")}
														className="sh-landing-page-text-content sh-landing-page-youtube-text" style={{ color: theme.palette.text.primary }}>
														<a className="sh-landing-page-footer-a-tag-styles sh-landing-page-footer-youtube">Youtube</a>
													</Typography>
												</div>
												<div className="sh-landing-page-footer-display-flex">
													<div>
														{/* <img className="sh-landing-page-footer-icon sh-landing-page-instagram-icon" src={instagram} alt="" /> */}

														<FaInstagram
															onClick={() => window.open("https://www.instagram.com/indosoul_academy/", "_blank")}
															className="sh-landing-page-footer-icon sh-landing-page-instagram-icon" />
													</div>
													<Typography variant="h5"
														onClick={() => window.open("https://www.instagram.com/indosoul_academy/", "_blank")}
														className="sh-landing-page-text-content sh-landing-page-instagram-text" style={{ color: theme.palette.text.primary }}>
														<a className="sh-landing-page-footer-a-tag-styles sh-landing-page-footer-instagram">Instagram</a>
													</Typography>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div>
							<hr style={{ color: theme.palette.text.primary }} />
						</div>
						<div className="sh-landing-page-footer-copyright-text">
							<Typography variant="h5" className="sh-landing-page-text-content sh-landing-page-loation-text" style={{ color: theme.palette.text.primary }}>
								<span> Copyright © 2024 Soul Swara. </span>&nbsp;&nbsp;|&nbsp;&nbsp;
								<span
									onClick={() => window.open(config.routes?.termsAndConditions, "_blank")}
									className="footer-content-link" >Terms and Conditions</span>&nbsp;&nbsp;|&nbsp;&nbsp;
								<span className="footer-content-link" onClick={() => window.open(config.routes?.privacyPolicy, "_blank")}>Privacy Policy</span>&nbsp;&nbsp;|&nbsp;&nbsp;
								<span onClick={() => window.open(config.routes?.refundPolicy, "_blank")} className="footer-content-link">Refund Policy</span>
								<span style={{ float: "right" }}>presented by VIOLIN TECHNIQUE WITH KARTHICK IYER</span>
							</Typography>
						</div>
					</div>
				</Container>
			</footer>
		</div>
	);

};

export default LandingPage;
