// ProtectedRoute.js
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import UserContext from "../context/AuthContext";
import config from "../config/appConfig";

const ProtectedRoutes = ({ element, requiredRole }: any) => {

	const { userDetails } = useContext(UserContext);

	let userDetailsFromSession: any = sessionStorage.getItem("userDetails");

	userDetailsFromSession = userDetailsFromSession ? JSON.parse(userDetailsFromSession) : {};

	if (!Object.keys(userDetails)?.length && !Object.keys(userDetailsFromSession)?.length) return <Navigate to="/login" />;
	if (userDetails?.userType == requiredRole || userDetails?.userType == config.userTypes.systemAdmin) {

		return element;
	
	}
  
	return <Navigate to="/login" />;

};

export default ProtectedRoutes;