/* eslint-disable no-undef */

interface Config {
    backendUrl?: string,
    firebase: {
        apiKey: string;
        authDomain: string;
        projectId: string;
        storageBucket: string;
        messagingSenderId: string;
        appId: string;
        measurementId: string;
    },
    routes?: any,
    dashboardItems: any,
    waveTypeOptions: any,
    roundingTypeOptions: any,
    smoothingTypeOptions: any,
    filterTypeOptions: any,
    addLessonOptions: any,
    skillLevel: any,
		courseList: any,
    all: string,
    enrolled: string,
    appDomain: any,
    acceptedFileImageVideo: any,
    monthNames: any,
    acceptedFilesImages: any,
    acceptedFilesVideos: any,
    acceptedImageFormats: any,
    acceptedVideoFormats: any,
    acceptedImageVideoFormats: any
	userTypes: any,
	razorPayKey: string,
	teaserVideo: string,
	razorPayAmountExponent: any
}

const config: Config = {
	backendUrl: process.env.REACT_APP_BACKEND_API_URL || "",
	firebase  : {
		apiKey           : process.env.REACT_APP_FIREBASE_API_KEY || "",
		authDomain       : process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "",
		projectId        : process.env.REACT_APP_FIREBASE_PROJECT_ID || "",
		storageBucket    : process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "",
		messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID || "",
		appId            : process.env.REACT_APP_FIREBASE_APP_ID || "",
		measurementId    : process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || ""
	},
	routes: {
		"landingPage"       : "/",
		"landingHome"       : "/home",
		"login"             : "/login",
		"forgotPassword"    : "/forgot-password",
		"register"          : "/register",
		"course"            : "/course",
		"soundAnalysis"     : "/sound-analysis",
		"userProfile"       : "/user-profile",
		"editProfile"       : "/edit-user-profile",
		"userIntro"         : "/user-intro",
		"userLevel"         : "/user-level",
		"modules"           : "/modules",
		"moduleIntro"       : "/module-intro",
		"payment"           : "/payment",
		"announcement"      : "/announcement",
		"video"             : "/level-content-by-module",
		"lessons"           : "/admin/list-lessons/add-lessons",
		"listCourse"        : "/admin/list-course",
		"listModules"       : "/admin/list-modules",
		"listLevels"        : "/admin/list-levels",
		"listLessons"       : "/admin/list-lessons",
		"dashboard"         : "/admin-dashboard",
		"addModulePage"     : "/admin/add-module",
		"addLevelPage"      : "/admin/add-level",
		"editCourse"        : "/admin/list-course/edit-course",
		"termsAndConditions": "/terms-and-conditions",
		"refundPolicy"      : "/refund-policy",
		"privacyPolicy"     : "/privacy-policy",
		"addPost"           : "/add-post",
		"purchaseHistory"   : "/purchase-history"
		
	},
	dashboardItems: [
		{
			id         : 1,
			title      : "POC",
			description: "POC For Sound Analysis.",
			path       : "/sound-analysis"
		}
	],
	waveTypeOptions: [
		{
			value: "Sine Wave",
			label: "sine"
		},
		{
			value: "Frequency Bars",
			label: "bars"
		}
	],
	roundingTypeOptions: [
		{
			value: "Note",
			label: "note"
		},
		{
			value: "HZ",
			label: "hz"
		},
		{
			value: "None",
			label: "none"
		}
	],
	smoothingTypeOptions: [
		{
			value: "None",
			label: "none"
		},
		{
			value: "Basic",
			label: "basic"
		},
		{
			value: "Very",
			label: "very"
		}
	],
	filterTypeOptions: [
		{
			value: "Bandpass",
			label: "bandpass"
		},
		{
			value: "Lowpass",
			label: "lowpass"
		},
		{
			value: "Highpass",
			label: "highpass"
		},
		{
			value: "Lowshelf",
			label: "lowshelf"
		},
		{
			value: "Highshelf",
			label: "highshelf"
		},
		{
			value: "Peaking",
			label: "peaking"
		},
		{
			value: "Notch",
			label: "notch"
		},
		{
			value: "Allpass",
			label: "allpass"
		}
	],
	addLessonOptions: [
		{
			value: "Skills and Songs",
			label: "skill and songs"
		},
		{
			value: "Self Placed Modules",
			label: "self placed modules"
		}
	],
	skillLevel: {
		"firstTimer": "firstTimer", "beginner": "beginner", "intermediate": "intermediate", "advanced": "advanced"
	},
	courseList: {
		"selfPaced": "Self Placed Modules", "songsAndSkill": "Skills And Songs"
	},
	all      : "all",
	enrolled : "enrolled",
	appDomain: process.env.REACT_APP_DOMAIN,

    
	monthNames: [
		"Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
	],
	acceptedFilesImages   : { "image/png": [], "image/jpeg": [], "image/jpg": [] },
	acceptedFilesVideos   : { "video/mp4": [], "video/quicktime": [], "video/x-msvideo": [] },
	acceptedFileImageVideo: {
		"image/png"      : [],
		"image/jpeg"     : [],
		"image/jpg"      : [],
		"video/mp4"      : [],
		"video/quicktime": [],
		"video/x-msvideo": []
	},
	acceptedImageFormats: [
		"image/png",
		"image/jpeg",
		"image/jpg"
	],
	acceptedVideoFormats: [
		"video/mp4",
		"video/mkv",
		"video/webm",
		"video/quicktime"
	],
	acceptedImageVideoFormats: [
		"image/jpeg",
		"image/jpg",
		"image/png",
		"video/mp4",
		"video/mkv",
		"video/webm"
	],
	userTypes: {
		"systemAdmin": "systemAdmin",
		"user"       : "user",
		"student"    : "student",
		"teacher"    : "teacher"
	},
	razorPayKey           : process.env.REACT_APP_RAZORPAY_KEY || "",
	teaserVideo           : "public/landing-page/SoulSwara Teaser.mp4",
	razorPayAmountExponent: {
		"AED":	2,
		"ALL":	2,
		"AMD":	2,
		"ARS":	2,
		"AUD":	2,
		"AWG":	2,
		"AZN":	2,
		"BAM":	2,
		"BBD":	2,
		"BDT":	2,
		"BGN":	2,
		"BHD":	3,
		"BIF":	0,
		"BMD":	2,
		"BND":	2,
		"BOB":	2,
		"BRL":	2,
		"BSD":	2,
		"BTN":	2,
		"BWP":	2,
		"BZD":	2,
		"CAD":	2,
		"CHF":	2,
		"CLP":	0,
		"CNY":	2,
		"COP":	2,
		"CRC":	2,
		"CUP":	2,
		"CVE":	2,
		"CZK":	2,
		"DJF":	0,
		"DKK":	2,
		"DOP":	2,
		"DZD":	2,
		"EGP":	2,
		"ETB":	2,
		"EUR":	2,
		"FJD":	2,
		"GBP":	2,
		"GHS":	2,
		"GIP":	2,
		"GMD":	2,
		"GNF":	0,
		"GTQ":	2,
		"GYD":	2,
		"HKD":	2,
		"HNL":	2,
		"HRK":	2,
		"HTG":	2,
		"HUF":	2,
		"IDR":	2,
		"ILS":	2,
		"INR":	2,
		"IQD":	3,
		"ISK":	0,
		"JMD":	2,
		"JOD":	3,
		"JPY":	0,
		"KES":	2,
		"KGS":	2,
		"KHR":	2,
		"KMF":	0,
		"KRW":	0,
		"KWD":	3,
		"KYD":	2,
		"KZT":	2,
		"LAK":	2,
		"LKR":	2,
		"LRD":	2,
		"LSL":	2,
		"MAD":	2,
		"MDL":	2,
		"MGA":	2,
		"MKD":	2,
		"MMK":	2,
		"MNT":	2,
		"MOP":	2,
		"MUR":	2,
		"MVR":	2,
		"MWK":	2,
		"MXN":	2,
		"MYR":	2,
		"MZN":	2,
		"NAD":	2,
		"NGN":	2,
		"NIO":	2,
		"NOK":	2,
		"NPR":	2,
		"NZD":	2,
		"OMR":	3,
		"PEN":	2,
		"PGK":	2,
		"PHP":	2,
		"PKR":	2,
		"PLN":	2,
		"PYG":	0,
		"QAR":	2,
		"RON":	2,
		"RSD":	2,
		"RUB":	2,
		"RWF":	0,
		"SAR":	2,
		"SCR":	2,
		"SEK":	2,
		"SGD":	2,
		"SLL":	2,
		"SOS":	2,
		"SSP":	2,
		"SVC":	2,
		"SZL":	2,
		"THB":	2,
		"TND":	3,
		"TRY":	2,
		"TTD":	2,
		"TWD":	2,
		"TZS":	2,
		"UAH":	2,
		"UGX":	0,
		"USD":	2,
		"UYU":	2,
		"UZS":	2,
		"VND":	0,
		"VUV":	0,
		"XAF":	0,
		"XCD":	2,
		"XOF":	0,
		"XPF":	0,
		"YER":	2,
		"ZAR":	2,
		"ZMW":	2
	}
    
};

export default config;
