import React, { useState, useEffect, useContext } from "react";
// import { useTheme } from "@mui/material/styles";
import {
	Grid,
	Button,
	useTheme,
	Container,
	useMediaQuery,
	Typography
} from "@mui/material";
import { useNavigate } from "react-router-dom";

// components
import SkillLevel from "./SkillLevel";
import MainButton from "../../../components/UI/extend/MainButton";
import CustomLoader from "../../../components/UI/cards/CustomLoader";
// Store
import { useDispatch, useSelector } from "../../../store";
import { setUser, setUserAddress } from "../../../store/slices/userSlice";
// Api
import { APIUrl } from "../../../api/endPoints";
import ApiRequest from "../../../api/ApiRequest";
// Config
import config from "../../../config/appConfig";
import { auth } from "../../../firebase";
import { Col, Row, Form } from "react-bootstrap";
import Select from "../../../components/UI/Select";
import countryConfig from "../../../config/countryConfig";
import stateConfig from "../../../config/stateConfig";
import toast from "react-hot-toast";
import UserContext from "../../../context/AuthContext";

const IntroPage = () => {

	const theme = useTheme();
	const user = auth.currentUser;
	const matchesXS = useMediaQuery(theme.breakpoints.down("md"));
	const { id } = useSelector((state: any) => state.user);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { setUserDetails } = useContext(UserContext);

	const [ selectedSkillLevel, setSelectedSkillLevel ] = useState<any>("");
	// const [ userDetail, setUserDetail ] = useState<any>({});
	const [ isUserDataLoading, setIsUserDataLoading ] = useState(true);
	const [ billingFirstName, setBillingFirstName ] = useState("");
	const [ billingLastName, setBillingLastName ] = useState("");
	const [ companyName, setCompanyName ] = useState("");
	const [ addressOne, setAddressOne ] = useState("");
	const [ addressTwo, setAddressTwo ] = useState("");
	const [ cityName, setCityName ] = useState("");
	const [ postalCode, setPostalCode ] = useState("");
	const [ stateName, setStateName ] = useState<any>([]);
	const [ countryName, setCountryName ] = useState<any>([]);
	const [ billingPhoneNo, setBillingPhoneNo ] = useState("");
	const countries: any = countryConfig.allCountries;
	const updatedCountries = countries.map((country: any) => ({
	  label: country.name,
	  value: country.name,
	  ...country
	}));
	const [ stateOptions, setStateOptions ] = useState<any>([]);
	const [ isAddressAdded, setIsAddressAdded ] = useState<any>(null);
	const [ isSkillLevelSelected, setIsSkillLevelSelected ] = useState<any>(null);
	
	let isDataFetched = false;

	useEffect(() => {

		if (isDataFetched && id !== "") return;
		isDataFetched = true;
		fetchUserDetails();

	}, [ user ]);

	useEffect(() => {

		if (isAddressAdded == true && isSkillLevelSelected == true) {

			navigate(config.routes.course);

		}

	}, [ isAddressAdded, isSkillLevelSelected ]);

	const fetchUserDetails = () => {

		setIsUserDataLoading(true);
		ApiRequest("get", APIUrl.getUserInfo).then((response: any) => {

			setUserDetails(response);
			setBillingFirstName(response?.firstName);
			setBillingLastName(response?.lastName);
			dispatch(setUser({ user: response }));
			if (response?.userAddress?.countryName) {

				setIsAddressAdded(response?.userAddress?.countryName !== "");

			} else {
				
				setIsAddressAdded(false);
			
			}
			setIsSkillLevelSelected(response?.skillLevel !== "");
			setIsUserDataLoading(false);
			setAddressOne(response?.userAddress?.addressOne);
			setAddressTwo(response?.userAddress?.addressTwo);
			setCityName(response?.userAddress?.cityName);
			setPostalCode(response?.userAddress?.postalCode);
			setBillingPhoneNo(response?.userAddress?.phoneNo);
			if (response?.userAddress?.countryName) {

				const selectedCountry = countries.find((country: any) => country.name === response?.userAddress?.countryName);
				setCountryName(selectedCountry);
				setStateName(response?.userAddress?.stateName);
				const stateOptions = updatedStates(selectedCountry?.isoCode);
				setStateOptions(stateOptions);
				setStateName(stateOptions.find((state: any) => state.value === response?.userAddress?.stateName));
			
			}


		}).catch((error: any) => {

			navigate(config.routes.landingHome);
			console.error(error);
			setIsUserDataLoading(false);
			

		});

	};

	const handleAddressInfoSaveChanges = () => {

		if (billingFirstName == "" || billingLastName == "" || addressOne == "" || cityName == "" || postalCode == "" || stateName == "" || countryName == "" || !billingFirstName || !billingLastName || !addressOne || !cityName || !postalCode || !stateName?.value || !countryName?.value) {

			toast.error("Please fill in all the required fields");

			return;

		}

		setIsUserDataLoading(true);

		const payLoad = {

			"firstName"  : billingFirstName,
			"lastName"   : billingLastName,
			"userAddress": {
				"companyName": companyName,
				"addressOne" : addressOne,
				"addressTwo" : addressTwo,
				"cityName"   : cityName,
				"postalCode" : postalCode,
				"stateName"  : stateName?.value,
				"countryName": countryName?.value,
				"phoneNo"    : billingPhoneNo
			}

		};
		ApiRequest("post", APIUrl.updateUserAddress, payLoad).then((response: any) => {
			
			if (response?.data) {
				
				dispatch(setUserAddress(response?.data));
				fetchUserDetails();

			}

		}).catch((error: any) => {

			console.error(error);
			setIsUserDataLoading(false);

		});

	};

	const handleContinue = () => {

		const payLoad = {
			"skillLevel": selectedSkillLevel
		};

		setIsUserDataLoading(true);

		ApiRequest("post", APIUrl.updateUserSkill, payLoad).then(() => {

			navigate(config.routes.course, { state: { fromIntro: true, skill: selectedSkillLevel } });
			setIsUserDataLoading(false);

		}).catch(error => {

			console.error(error);
			setIsUserDataLoading(false);

		});

	};

	const formData = [
		{
			"title"      : "First Name",
			"value"      : billingFirstName,
			"onChange"   : setBillingFirstName,
			"placeholder": "Enter the First Name",
			"isSelect"   : false,
			"isMandatory": true
		},
		{
			"title"      : "Last Name",
			"value"      : billingLastName,
			"onChange"   : setBillingLastName,
			"placeholder": "Enter the Last Name",
			"isSelect"   : false,
			"isMandatory": true
		},
		{
			"title"      : "Company",
			"value"      : companyName,
			"onChange"   : setCompanyName,
			"placeholder": "Enter the Company Name",
			"isSelect"   : false,
			"isMandatory": false

		},
		{
			"title"      : "Address 1",
			"value"      : addressOne,
			"onChange"   : setAddressOne,
			"placeholder": "Enter the Address",
			"isSelect"   : false,
			"isMandatory": true
		},
		{
			"title"      : "Address 2",
			"value"      : addressTwo,
			"onChange"   : setAddressTwo,
			"placeholder": "Enter the Address",
			"isSelect"   : false,
			"isMandatory": false
		},
		{
			"title"      : "City",
			"value"      : cityName,
			"onChange"   : setCityName,
			"placeholder": "Enter the City Name",
			"isSelect"   : false,
			"isMandatory": true
		},
		{
			"title"      : "Postal Code",
			"value"      : postalCode,
			"onChange"   : setPostalCode,
			"placeholder": "Enter the Postal Code",
			"isSelect"   : false,
			"isMandatory": true
		},
		{
			"title"      : "Country",
			"options"    : updatedCountries,
			"value"      : countryName,
			"onChange"   : setCountryName,
			"placeholder": "Enter the Country Name",
			"isSelect"   : true,
			"isMandatory": true
		},
		{
			"title"      : "State",
			"options"    : stateOptions,
			"value"      : stateName,
			"onChange"   : setStateName,
			"placeholder": "Enter the State Name",
			"isSelect"   : true,
			"isMandatory": true
		},
		
		{
			"title"      : "Phone Number",
			"value"      : billingPhoneNo,
			"onChange"   : setBillingPhoneNo,
			"placeholder": "Enter the Phone Number",
			"isSelect"   : false,
			"isMandatory": false
		}
	];

	const updatedStates = (countryId: any) => {

		const statesOptions = [
		  {
				label: "-None-",
				value: ""
		  }
		];
	
		if (countryId) {
	
		  stateConfig.countryState[countryId].map((state: any) => {
	
				statesOptions.push({
			  label: state.name,
			  value: state.name,
			  ...state
				});
		  
		  });
	
		}
		
		return statesOptions;
	  
	  };


	return <> {isUserDataLoading ? <CustomLoader /> :
		<Container>
			{
				!isAddressAdded ? <Row className="sh-level-page-container">
					<Col className="sh-level-page-left-side-container">
						<Typography variant="h2" gutterBottom className="" style={{ paddingBottom: "1em" }}>
							Journey Begins Here
						</Typography>
						<Typography variant="h5" gutterBottom className="" style={{ paddingBottom: "1em" }}>
							Please fill all the required <span style={{ color: "red" }}>*&nbsp;</span> fields to continue.
						</Typography>
						{
							formData.map((data: any, index: number) => {

								if (data.isSelect) {

									return <Row
										key={index}
										style={{ display: "flex", paddingBlock: "1em" }}>
										<Col md={3} lg={3} xl={3} sm={12} xs={12}>
											<Typography variant="subtitle1">{data.title}&nbsp;
												{data.isMandatory && <span style={{ color: "red" }}>*</span>}
											</Typography>
										</Col>
										<Col md={6} lg={6} xl={6} sm={12} xs={12}>
											<Select
												options={data.title === "Location" ? updatedCountries : stateOptions}
												value={data.value}
												handleChange={(e: any) => {

													if (data.title === "Location") {

														data.onChange(e);

														setStateOptions(updatedStates(e.isoCode));
													
													} else {
														
														data.onChange(e);

													}
												
												}}
												placeholder={data.placeholder}
												style={{ width: "100%" }}
											/>
										</Col>
										
									</Row>;
								
								} else {

									return <Row
										key={index}
										style={{ display: "flex", paddingBlock: "1em" }}
									>
										<Col md={3} lg={3} xl={3} sm={12} xs={12}>
											<Typography variant="subtitle1">{data.title}&nbsp;
												{data.isMandatory && <span style={{ color: "red" }}>*</span>}</Typography>
										</Col>
										<Col md={6} lg={6} xl={6} sm={12} xs={12}>
											<Form.Control
												id="outlined-basic"
												style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}
												color="secondary"
												placeholder={data.placeholder}
												value={data.value}
												onChange={e => data.onChange(e.target.value)}
												onFocus={(e: any) => {
	
													const focusColor: string = theme.palette.secondary.main;
	
													e.target.style.borderColor = focusColor;
													e.target.style.borderWidth = "1.5px";
													e.target.style.boxShadow = `rgba(0, 0, 0, 0.16) 0px 1px 0px, ${focusColor} 0px 0px 0px 0.3px`;
	
												}}
												onBlur={(e: any) => {
	
													e.target.style.borderColor = "";
													e.target.style.boxShadow = "";
	
												}}
													
											/>
											<style>
												{` #outlined-basic::placeholder { color: ${theme.palette.text.secondary}; /* Change placeholder color */ opacity: 1; font-size: .8em;}`}
											</style>
										</Col>
									</Row>;

								}

						
							})
						}
					</Col>
				</Row> : !isSkillLevelSelected && <SkillLevel setSelectedSkillLevel={setSelectedSkillLevel} />
			}
			
			<Grid item xs={12} className="intro-page-next-btn-con">
				<MainButton
					type="scale"
					direction="bottom"
					offset={matchesXS ? 0 : 20}
					scale={0.9}>
					{
						!isAddressAdded ? <Button
							variant="contained"
							color="primary"
							sx={{
								borderRadius: "50px",
								fontSize    : "1.2rem"
							}}
							size={matchesXS ? "small" : "medium"}
							onClick={handleAddressInfoSaveChanges}>Next</Button> : !isSkillLevelSelected && <Button
							variant="contained"
							color="primary"
							sx={{
								borderRadius: "50px",
								fontSize    : "1.2rem"
							}}
							size={matchesXS ? "small" : "medium"}
							onClick={handleContinue}>Continue</Button>
					}
				</MainButton>
			</Grid>
		</Container>
	}
	</>

	;

};

export default IntroPage;
